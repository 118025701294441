import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { SettingsService } from '@core/settings/settings.service';
import { Settings } from '@core/settings/settings.types';
import { SessionService } from '@core/session/session.service';
import { SessionData } from '@core/session/session.type';
import { FlatShippingRate } from '@core/shipping/shipping-rate-flat.types';


/**
 * Service for managing Mondial Relay integration.
 */
@Injectable({
    providedIn: 'root'
})
export class MondialRelayService {

    /**
     * Flag indicating whether the code is running in a browser environment.
     */
    private _isBrowser: boolean;

    /**
     * The settings of the current merchant.
     */
    private _settings: Settings;

    /**
     * The session data.
     */
    private _sessionData: SessionData;

    /**
     * Flag indicating whether the scripts have been loaded.
     */
    private _scriptsLoaded: boolean = false;

    /**
     * Constructs a new instance of the MondialRelayService.
     * @param _platformId - The platform ID.
     * @param _document - The document object.
     * @param _settingsService - The settings service.
     * @param _sessionService - The session service.
     */
    constructor(
        @Inject(PLATFORM_ID) private _platformId: any,
        @Inject(DOCUMENT) private _document: any,
        private _settingsService: SettingsService,
        private _sessionService: SessionService,
    ) {
        this._isBrowser = isPlatformBrowser(this._platformId);
        if (!this._isBrowser) {
            return;
        }

        // Get the settings
        this._settingsService.settings$
            .subscribe((settings: Settings | null) => {
                const newSettingsLoaded: boolean = this._settings?._id.toString() !== settings?._id.toString();
                this._settings = settings;
                if (newSettingsLoaded && this.shouldLoadScripts()) {
                    this.loadScripts();
                    this._scriptsLoaded = true;
                }
            });

        // Get the session data
        this._sessionService.data$
            .subscribe((sessionData: SessionData) => {
                this._sessionData = sessionData;
            });
    }

    /**
     * Checks if the scripts should be loaded.
     * @returns True if the scripts should be loaded, false otherwise.
     */
    shouldLoadScripts(): boolean {
        if (!this._settings || this._scriptsLoaded) {
            return false;
        }
        const isMondialRelayConnected: boolean = this._settings?.mondialRelayConnected ?? false;
        const hasMondialRelayApiKey: boolean = this._settings?.mondialRelayInfo?.apiKey != null;
        const hasMondialRelayRate: boolean = this._settings?.shippingFlatRates?.some((rate: FlatShippingRate) => rate.destinationType === 'relay') ?? false;
        return hasMondialRelayRate;
    }

    /**
     * Checks if the element should be loaded.
     * @returns True if the element should be loaded, false otherwise.
     */
    shouldLoadElement(): boolean {
        if (!this._isBrowser || !this._settings || !this._sessionData) {
            return false;
        }
        const isMondialRelayConnected: boolean = this._settings?.mondialRelayConnected ?? false;
        const hasMondialRelayApiKey: boolean = this._settings?.mondialRelayInfo?.apiKey != null;
        const hasMondialRelaySelectedRate: boolean = this._sessionData?.shipping?.rateType === 'flat' && (this._sessionData?.shipping?.selectedRate as FlatShippingRate)?.destinationType === 'relay';
        const hasAddress: boolean = this._sessionData?.shipping?.address?.country != null;
        const hasCity: boolean = this._sessionData?.shipping?.address?.city != null;
        const hasZip: boolean = this._sessionData?.shipping?.address?.zip != null;
        return isMondialRelayConnected
            && hasMondialRelayApiKey
            && hasMondialRelaySelectedRate
            && hasAddress
            && hasCity
            && hasZip;
    }

    /**
     * Loads the required scripts for Mondial Relay.
     */
    loadScripts(): void {
        const jqueryScript = this._document.createElement('script');
        jqueryScript.type = 'text/javascript';
        jqueryScript.src = 'assets/js/jquery-2.2.4.min.js';
        this._document.body.appendChild(jqueryScript);
        const leafletScript = this._document.createElement('script');
        leafletScript.type = 'text/javascript';
        leafletScript.src = '//unpkg.com/leaflet/dist/leaflet.js';
        leafletScript.async = true;
        leafletScript.defer = true;
        this._document.body.appendChild(leafletScript);
        const mondialRelayScript = this._document.createElement('script');
        mondialRelayScript.type = 'text/javascript';
        mondialRelayScript.src = 'https://widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js';
        mondialRelayScript.async = true;
        mondialRelayScript.defer = true;
        this._document.body.appendChild(mondialRelayScript);
        console.log('Mondial Relay loaded.');
    }
}